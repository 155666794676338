@import "../scss/framework/tools";

.Main {
    padding-top: resp(105);
    background-color: var(--background);
    width: 100vw;

    overflow: hidden;

    color: var(--white);

    @include mq($until: tablet) {
        padding-top: respM(150);

    }


    @include mq($from: tablet) {
        height: 100vh;
    }


    :global {
        .container {
            display: flex;
            align-items: center;
            border-top: 1px solid var(--white);

            @include mq($until: tablet) {
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }
}

.Text {
    position: relative;
    z-index: 1;
    width: susy-span(3.5 of 10);
    padding-right: susy-span(.5 of 11);
    flex: none;

    @include mq($until: tablet) {
        width: 100%;
        padding-right: 0;
    }

    > * {
        font-weight: 600;
    }

    h1 {
        margin-bottom: resp(40);
        font-size: resp(69);

        @include mq($until: tablet) {
            font-size: 4rem;
            line-height: 5rem;

            margin-top: 40px;
        }
    }

    > p {
        padding-right: susy-span(.5 of 4);
    }
}
