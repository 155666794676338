@import "framework/variables";
@import "framework/_tools";


html {
    background-color: var(--white);
}

body {
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    color: var(--black);
    //@include fluid-type(14px, 16px);
    font-size: 1.111vw;
    overflow: overlay;


    @include mq($until: tablet) {
        font-size: 1.4rem;
    }

    @include mq($until: tablet) {
        font-size: 1.2rem;
    }
}

button {
    border: 0;
    outline: 0;
    background-color: transparent;
    cursor: pointer;
}

figure {
    max-width: 100%;
    overflow: hidden;
    flex: 1;

    img {
        width: 100%;
        height: auto;
        display: block;

        @include mq($until: tablet) {
            width: 100%;
        }
    }
}

.icon {
    display: block;
    line-height: 0;
}

section {
    &:not(:first-of-type) {
        padding-top: resp(120);

        @include mq($until: tablet) {
            padding-top: 70px;
        }
    }
}

h1 {
    font-weight: 300;
    font-size: 5.556vw;

    line-height: 1.1;

    text-align: left;

    @include mq($until: tablet) {
        font-size: 5rem;
        line-height: 1;
    }

    strong {
        font-weight: 600;
        display: block;
    }
}

h2 {
    //@include fluid-type(60px, 70px);
    font-size: 4.861vw;
    padding-bottom: resp(70);

    border-bottom: 2px solid var(--black);

    @include mq($until: tablet) {
        padding-bottom: 24px;
        font-size: 3.6rem;
        line-height: 4.6rem;

        display: flex;

        span {
            margin-right: 10px;
        }
    }
}

h3 {
    font-weight: 600;
    //@include fluid-type(24px, 30px);
    font-size: 2.083vw;

    @include mq($until: tablet) {
        font-size: 2.4rem;
    }
}

.head-text {
    //@include fluid-type(36px, 40px);
    font-size: 2.778vw;
    line-height: 3.889vw;

    @include mq($until: tablet) {
        font-size: 2.6rem;
        line-height: 3.6rem;
    }

    &.aside {
        width: susy-span(5 of 10);

        @include mq($until: tablet) {
            width: 100%;
        }
    }

    small {
        display: block;
        margin-top: resp(20);
        @include paragraph;
    }
}

.link--absolute {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

p {
    @include paragraph();
}

strong {
    font-weight: bold;
}

.c3-chart-arcs-title {
    //@include fluid-type(26px, 30px);
    font-size: 2.083vw;
    font-weight: 700;

    @include mq($until: tablet) {
        font-size: 3rem;
    }
}

.c3-legend-item {
    text {
        //@include fluid-type(12px, 16px);
        font-size: 1.111vw;

        @include mq($until: tablet) {
            font-size: 1.6rem;
        }
    }
}

.c3-legend-background {
    fill: transparent;
    stroke: transparent;
}

.c3-chart-arc {
    path {
        stroke: none;
    }

    text {
        @include paragraph;
        fill: var(--black);
        @extend %DINM;
    }
}

$colors: 'green', 'blue';

@each $color in $colors {
    .bg-#{$color} {
        background-color: var(--#{$color});
    }
}

.scroll-content {
    height: 100%;
    display: flex;
    align-items: center;
}
.scrollbar-track {
    visibility: hidden;
}

.custom-scrollbar {
    -webkit-appearance: none;
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);

    background: var(--white);
    width: 250px;
    height: 6px;
    border-radius: 4px;

    cursor: pointer;

    &::-webkit-slider-thumb, &::-moz-range-thumb {
        background-color: var(--blue2);
    }
}
