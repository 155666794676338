:root {
    --black: #000;
    --white: #fff;
    --grey: #D8D8D8;

    --green: #74B743;
    --blue: #32206C;
    --blue2: #5696FC;

    --background: #2F3D88;
}
