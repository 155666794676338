@import "../scss/framework/tools";

.Main {
    background-color: var(--background);

    position: relative;
    z-index: 0;
    width: susy-span(6.5 of 10);

    @include mq($until: tablet) {
        margin-top: respM(50);
        padding-bottom: respM(80);

        width: 100%;
    }

    :global {
        .container {
            @include mq($until: tablet) {
                overflow: visible;
            }
        }
    }

}

.SliderWrapper {
    width: susy-span(10 of 10);

    min-height: resp(500);
}

.Slider {

    display: flex;
    align-items: center;
    overflow: hidden;

    &:hover {
        :global {
            * {
                cursor: none !important;
            }
        }
    }

    [class*='swiper-button'] {
        display: none;
    }
}

.SlideItem {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: none;

    width: resp(360);
    height: resp(500);

    box-sizing: border-box;

    color: var(--white);

    background-color: var(--blue2);

    @include mq($until: tablet) {
        width: 310px;
        height: 450px;
    }

    &:hover {
        .Read {
            opacity: 1;
            transform: none;
        }
    }

}

.Content {
    padding: resp(20) resp(30);

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;


    @include mq($until: tablet) {
        padding: respM(20) respM(30);
    }
}

.Title {
    padding-right: resp(20);
    padding-bottom: resp(25);

    font-size: resp(30);
    line-height: resp(40);
    font-weight: 600;

    border-bottom: 0;


    @include mq($until: tablet) {
        font-size: 3rem;
        line-height: 4rem;

        padding-bottom: 25px;
    }
}

.Read {
    display: flex;
    align-items: center;
    font-size: resp(18);
    font-weight: 600;

    transition: all .3s;

    opacity: 0;
    transform: translateX(-20px);

    @include mq($until: tablet) {
        opacity: 1;
        transform: none;
        font-size: 1.5rem;
    }

    > span {
        position: relative;

        &:after {
            content: '';

            position: absolute;
            top: calc(100% + 5px);
            left: 0;

            background-color: var(--white);
            width: 100%;
            height: 2px;
        }
    }

    :global {
        .icon {
            margin-right: resp(10);

            @include mq($until: tablet) {
                margin-right: 10px;
            }
        }
    }
}

.SeeWallet {
    position: relative;
    z-index: 3;

    a {
        display: flex;
        align-items: center;

        &:hover {
            text-decoration: underline;
        }
    }

    :global {
        .icon {
            margin-right: resp(10);

            @include mq($until: tablet) {
                margin-right: 10px;
            }
        }
    }
}

.Cursor {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    width: resp(80);
    height: resp(80);

    border: 1px solid var(--white);
    border-radius: 100%;

    opacity: 0;

    pointer-events: none;

    display: flex;
    align-items: center;
    justify-content: center;

    > span {
        display: block;

        transform: scale(0);

        width: 5px;
        height: 5px;
        border-radius: 100%;
        background-color: var(--white);
    }

    &:before, &:after {
        content:'';

        width: 0;
        height: 0;
        border-style: solid;

        position: absolute;
        top: 50%;
        margin-top: -8px;
    }

    &:before {
        right: calc(100% + 15px);

        border-width: 8px 12px 8px 0;
        border-color: transparent var(--white) transparent transparent;
    }

    &:after {
        left: calc(100% + 15px);

        border-width: 8px 0 8px 12px;
        border-color: transparent transparent transparent var(--white);
    }
}

:global {
    .swiper-button-prev {
        flex-direction: row-reverse;

        .icon {
            transform-origin: center center;
            transform: rotate(180deg);
        }
    }
}
