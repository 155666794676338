@import "../scss/framework/tools";

.Main {
    position: absolute;
    top: resp(40);
    left: 0;

    color: var(--white);

    width: 100%;

    z-index: 2;

    @include mq($until: tablet) {
        top: 60px;
    }

    * {
        display: flex;
        justify-content: space-between;
        align-items: center;


    }

    :global {
        .container {
            @include mq($until: tablet) {
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }
}

.Logo {
    img {
        width: resp(127);
        height: auto;

        @include mq($until: tablet) {
            width: 127px;
        }
    }
}

.Boursier {
    @include mq($until: tablet) {
        margin-top: 10px;
    }

    img {
        width: resp(127);
        height: auto;

        margin-left: 20px;

        @include mq($until: tablet) {
            width: 127px;
        }
    }
}
