/*------------------------------------*\
  #GRID
\*------------------------------------*/
@import "~susy";

$susy: (
        columns: susy-repeat(12),
        gutters: 0,
        spread: 'narrow',
        container-spread: 'narrow',
        debug: (
                image: show,
                color: grey,
                output: background,
                toggle: top right,
        ),
);

.container {
    position: relative;

    box-sizing: border-box;

    //max-width: 1200px;
    height: 100%;
    margin: 0 susy-span(1 of 12);

    @include mq($until: tablet) {
        //overflow: hidden;
        //max-width: 350px;
       // width: 86%;
        height: initial;
    }
}
