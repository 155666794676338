@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');


@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('../../fonts/DINNextLTPro-Medium.woff2') format('woff2'),
    url('../../fonts/DINNextLTPro-Medium.woff') format('woff'),
    url('../../fonts/DINNextLTPro-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('../../fonts/DINNextLTPro-Bold.woff2') format('woff2'),
    url('../../fonts/DINNextLTPro-Bold.woff') format('woff'),
    url('../../fonts/DINNextLTPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


%DINB {
    font-family: 'DIN Next LT Pro', serif;
    font-weight: bold;
}

%DINM {
    font-family: 'DIN Next LT Pro', serif;
    font-weight: 500;
}
